import React from "react";
import {
  useDeleteStoreMutation,
  useGetStoresQuery,
  useUpdateStoreMutation,
  useGetCategoriesQuery,
} from "state/api";
import getColumns from "./columns";

import DataGridEditableRow from "components/DataGridEditableRow";
import Loader from "components/Loader";

export const Stores = () => {
  const { data, isLoading } = useGetCategoriesQuery();

  if (isLoading === true) {
    return <Loader />;
  }
  const categories = data.map((cat) => ({ value: cat.id, label: cat.name }));
  return (
    <DataGridEditableRow
      useGet={useGetStoresQuery}
      useUpdate={useUpdateStoreMutation}
      useDelete={useDeleteStoreMutation}
      getColumns={getColumns(categories)}
      createLink="/stores/create"
      getRowHeight={() => "auto"}
    />
  );
};

export default React.memo(Stores);

import CategoryIcon from "@mui/icons-material/Category";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import HomeIcon from "@mui/icons-material/Home";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PreviewIcon from "@mui/icons-material/Preview";
import RestoreIcon from "@mui/icons-material/Restore";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ModifiedBy from "components/ModifiedBy";
import { Link as RouterLink } from "react-router-dom";
import TitleForm from "./TitleForm";
import React from "react";

import { renderActionsCell } from "@mui/x-data-grid";

export default function getColumns(categories) {
  return function (apiRef, unsavedChangesRef, setHasUnsavedRows) {
    return [
      {
        field: "image",
        headerName: "Imagem",
        cellClassName: "logo--cell",
        renderCell: ({ value, row }) => {
          return (
            <Avatar
              sx={{ width: 60, height: 60 }}
              src={value}
              alt={row.name}
              variant="rounded"
            >
              <CategoryIcon />
            </Avatar>
          );
        },
      },

      {
        field: "name",
        colSpan: 3,
        headerName: "Nome",
        renderCell: (params) => {
          return (
            <>
              <Tooltip title="Nome" placement="left-start">
                <Typography
                  variant="h6"
                  component="a"
                  href={params.row.absolute_url}
                  target="_blank"
                >
                  {params.row.name}
                </Typography>
              </Tooltip>
              <Tooltip title="SEO Título" placement="left">
                <Typography variant="subtitle2">{params.row.title}</Typography>
              </Tooltip>
              <Tooltip title="SEO Subtítulo" placement="left-end">
                <Typography variant="caption">{params.row.subtitle}</Typography>
              </Tooltip>
            </>
          );
        },
        renderEditCell: (params) => {
          return <TitleForm store={params.row} />;
        },
        flex: 1,
        editable: true,
      },
      {
        field: "title",
        headerName: "",
        editable: true,
        sortable: false,
        headerClassName: "hidden-header-menu",
      },
      {
        field: "subtitle",
        headerName: "",
        editable: true,
        sortable: false,
        headerClassName: "hidden-header-menu",
      },
      {
        field: "slug",
        headerName: "Slug Principal",
        flex: 0.5,
        editable: true,
      },
      {
        field: "category",
        headerName: "Categoria Principal",
        flex: 0.5,
        editable: true,
        type: "singleSelect",
        valueOptions: categories,
      },
      {
        field: "network",
        headerName: "Rede",
        flex: 0.5,
        editable: false,
      },
      {
        field: "disabled",
        renderHeader: () => (
          <Tooltip title="Desativada?">
            <VisibilityOffIcon />
          </Tooltip>
        ),
        type: "boolean",
        editable: true,
        sortable: false,
        flex: 0.3,
      },
      {
        field: "show_at_home",
        renderHeader: () => (
          <Tooltip title="Homepage?">
            <HomeIcon />
          </Tooltip>
        ),
        type: "boolean",
        editable: true,
        sortable: false,
        flex: 0.3,
      },
      {
        field: "uncover_coupon",
        renderHeader: () => (
          <Tooltip title="Revelar código?">
            <PreviewIcon />
          </Tooltip>
        ),
        type: "boolean",
        editable: true,
        sortable: false,
        flex: 0.3,
      },
      {
        field: "weight",
        renderHeader: () => (
          <Tooltip title="Peso">
            <FitnessCenterIcon />
          </Tooltip>
        ),
        type: "number",
        editable: true,
        sortable: false,
        flex: 0.3,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        flex: 0.5,
        renderCell: (params) => {
          return (
            <>
              {renderActionsCell(params)}
              <ModifiedBy {...params.row} newLine={false} fontSize="0.8rem" />
            </>
          );
        },
        getActions: ({ id, row }) => {
          return [
            <Tooltip key="edit" title="Editar">
              <GridActionsCellItem
                component={RouterLink}
                icon={<EditIcon />}
                to={`update/${id}`}
                label="Editar"
              />
            </Tooltip>,
            <Tooltip key="undo" title="Desfazer">
              <>
                {" "}
                {/* This is to fix a BUG in MUI Tooltip when the button is disabled */}
                <GridActionsCellItem
                  icon={<RestoreIcon />}
                  label="Desfazer"
                  disabled={
                    unsavedChangesRef.current.unsavedRows[id] === undefined
                  }
                  onClick={() => {
                    apiRef.current.updateRows([
                      unsavedChangesRef.current.rowsBeforeChange[id],
                    ]);
                    delete unsavedChangesRef.current.rowsBeforeChange[id];
                    delete unsavedChangesRef.current.unsavedRows[id];
                    setHasUnsavedRows(
                      Object.keys(unsavedChangesRef.current.unsavedRows)
                        .length > 0,
                    );
                  }}
                />
              </>
            </Tooltip>,
            <GridActionsCellItem
              key="openPage"
              showInMenu
              component={Link}
              icon={<OpenInNewIcon />}
              label="Abrir página"
              href={row.absolute_url}
              target="_blank"
            />,
            <GridActionsCellItem
              key="delete"
              showInMenu
              icon={<DeleteIcon />}
              label="Excluir"
              onClick={() => {
                unsavedChangesRef.current.unsavedRows[id] = {
                  ...row,
                  _action: "delete",
                };
                if (!unsavedChangesRef.current.rowsBeforeChange[id]) {
                  unsavedChangesRef.current.rowsBeforeChange[id] = row;
                }
                setHasUnsavedRows(true);
                apiRef.current.updateRows([row]); // to trigger row render
              }}
            />,
          ];
        },
      },
    ];
  };
}

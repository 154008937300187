import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RestoreIcon from "@mui/icons-material/Restore";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ModifiedBy from "components/ModifiedBy";
import { Link as RouterLink } from "react-router-dom";
import TitleDescriptionForm from "./TitleForm";
import { renderActionsCell } from "@mui/x-data-grid";
import FlagsForm from "./FlagsForm";
import StoreSlug from "./StoreSlug";
import TitleDescription from "./TitleDescription";
import CodeList from "./CodeList";
import Flags from "./Flags";
import React from "react";
import CodesForm from "./CodesForm";

export default function getColumns(categories, specials, couponKinds) {
  return function (apiRef, unsavedChangesRef, setHasUnsavedRows) {
    return [
      {
        field: "store",
        headerName: "Loja e Slug",
        flex: 0.7,
        editable: false,
        renderCell: (params) => <StoreSlug coupon={params.row} />,
      },
      {
        field: "title",
        headerName: "Título e Descrição",
        renderCell: (params) => <TitleDescription coupon={params.row} />,
        renderEditCell: (params) => (
          <TitleDescriptionForm coupon={params.row} />
        ),
        flex: 1.7,
        editable: true,
      },
      {
        field: "description",
        editable: true,
        sortable: false,
      },
      {
        field: "codes",
        headerName: "Códigos",
        flex: 0.5,
        editable: true,
        renderCell: (params) => <CodeList coupon={params.row} />,
        renderEditCell: (params) => <CodesForm coupon={params.row} />,
      },
      {
        field: "kind",
        headerName: "Tipo de cupom",
        flex: 0.5,
        editable: true,
        type: "singleSelect",
        valueOptions: couponKinds,
      },
      {
        field: "category",
        headerName: "Categoria Principal",
        flex: 0.5,
        editable: true,
        type: "singleSelect",
        valueOptions: categories,
      },
      {
        field: "specials",
        headerName: "Especiais",
        flex: 0.5,
        editable: true,
        type: "singleSelect",
        valueOptions: specials,
      },

      {
        field: "exclusive",
        headerName: "Flags",
        flex: 0.4,
        editable: true,
        renderCell: (params) => <Flags coupon={params.row} />,
        renderEditCell: (params) => {
          return <FlagsForm coupon={params.row} />;
        },
      },
      {
        field: "disabled",
        type: "boolean",
        editable: true,
        sortable: false,
      },
      {
        field: "show_at_home",
        type: "boolean",
        editable: true,
        sortable: false,
      },
      {
        field: "track_url",
        type: "boolean",
        editable: true,
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        flex: 0.5,
        renderCell: (params) => {
          return (
            <>
              {renderActionsCell(params)}
              <ModifiedBy {...params.row} newLine={false} fontSize="0.8rem" />
            </>
          );
        },
        getActions: ({ id, row }) => {
          return [
            <Tooltip title="Editar" key="edit">
              <GridActionsCellItem
                component={RouterLink}
                icon={<EditIcon />}
                to={`update/${id}`}
                label="Editar"
              />
            </Tooltip>,
            <Tooltip title="Desfazer" key="undo">
              <>
                {" "}
                {/* This is to fix a BUG in MUI Tooltip when the button is disabled */}
                <GridActionsCellItem
                  icon={<RestoreIcon />}
                  label="Desfazer"
                  disabled={
                    unsavedChangesRef.current.unsavedRows[id] === undefined
                  }
                  onClick={() => {
                    apiRef.current.updateRows([
                      unsavedChangesRef.current.rowsBeforeChange[id],
                    ]);
                    delete unsavedChangesRef.current.rowsBeforeChange[id];
                    delete unsavedChangesRef.current.unsavedRows[id];
                    setHasUnsavedRows(
                      Object.keys(unsavedChangesRef.current.unsavedRows)
                        .length > 0,
                    );
                  }}
                />
              </>
            </Tooltip>,
            <GridActionsCellItem
              showInMenu
              component={Link}
              icon={<OpenInNewIcon />}
              label="Abrir página"
              href={row.absolute_url}
              target="_blank"
              key="openPage"
            />,
            <GridActionsCellItem
              showInMenu
              icon={<DeleteIcon />}
              label="Excluir"
              onClick={() => {
                unsavedChangesRef.current.unsavedRows[id] = {
                  ...row,
                  _action: "delete",
                };
                if (!unsavedChangesRef.current.rowsBeforeChange[id]) {
                  unsavedChangesRef.current.rowsBeforeChange[id] = row;
                }
                setHasUnsavedRows(true);
                apiRef.current.updateRows([row]); // to trigger row render
              }}
              key="delete"
            />,
          ];
        },
      },
    ];
  };
}

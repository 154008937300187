import React from "react";
import {
  useGetCouponsQuery,
  useGetCategoriesQuery,
  useGetSpecialsQuery,
  useGetCouponKindsQuery,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
} from "state/api";
import getColumns from "./columns";

import DataGridEditableRow from "components/DataGridEditableRow";
import Loader from "components/Loader";

export const Coupons = () => {
  let { data: categories, isLoading: isLoadingCategories } =
    useGetCategoriesQuery();
  let { data: specials, isLoading: isLoadingSpecials } = useGetSpecialsQuery();
  let { data: couponKinds, isLoading: isLoadingCouponKinds } =
    useGetCouponKindsQuery();

  if (
    isLoadingCategories === true ||
    isLoadingSpecials === true ||
    isLoadingCouponKinds
  ) {
    return <Loader />;
  }
  categories = categories.map((cat) => ({ value: cat.id, label: cat.name }));
  specials = specials.map((special) => ({
    value: special.id,
    label: special.name,
  }));
  couponKinds = couponKinds.map((kind) => ({
    value: kind.id,
    label: kind.name,
  }));
  return (
    <DataGridEditableRow
      useGet={useGetCouponsQuery}
      useUpdate={useUpdateCouponMutation}
      useDelete={useDeleteCouponMutation}
      getColumns={getColumns(categories, specials, couponKinds)}
      createLink="/coupons/create"
      getRowHeight={() => "auto"}
      initialState={{
        columns: {
          columnVisibilityModel: {
            description: false,
            disabled: false,
            show_at_home: false,
            track_url: false,
          },
        },
      }}
    />
  );
};

export default React.memo(Coupons);

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import { gridClasses, useGridApiRef } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import React from "react";
import { DataGridEditableRowToolbar } from "./DataGridEditableRowToolbar";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "none",
  height: "calc(100vh - 75px)",
  [`& .${gridClasses.row}.row--removed`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgba(255, 170, 170, 0.3)"
        : "rgba(255, 170, 170, 1)",
  },
  [`& .${gridClasses.row}.row--edited`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgba(255, 254, 176, 0.3)"
        : "rgba(255, 254, 176, 1)",
  },
  [`& .${gridClasses.cell}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  [`& .${gridClasses.columnHeader}.hidden-header-menu`]: {
    [`&  .${gridClasses.iconButtonContainer}, &  .${gridClasses.menuIcon},  &  .${gridClasses.iconSeparator}`]:
      {
        display: "none",
      },
  },
}));

export const DataGridEditableRow = ({
  useGet,
  useUpdate,
  useDelete,
  getColumns,
  createLink,
  ...props
}) => {
  const gridRef = useGridApiRef();
  const unsavedChangesRef = React.useRef({
    unsavedRows: {},
    rowsBeforeChange: {},
  });

  const [hasUnsavedRows, setHasUnsavedRows] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(null);

  const [updateMutation] = useUpdate();
  const [deleteMutation] = useDelete();
  const { data, isFetching, refetch, error } = useGet({
    pageSize: 100000,
  });
  const handleCloseSnackbar = React.useCallback(() => setSnackbar(null), []);

  const processRowUpdate = async (newRow, oldRow) => {
    unsavedChangesRef.current.unsavedRows[newRow.id] = {
      ...newRow,
      _action: "update",
    };
    if (!unsavedChangesRef.current.rowsBeforeChange[newRow.id]) {
      unsavedChangesRef.current.rowsBeforeChange[newRow.id] = oldRow;
    }
    setHasUnsavedRows(true);
    return newRow;
  };

  const discardAllChanges = React.useCallback(() => {
    Object.values(unsavedChangesRef.current.rowsBeforeChange).forEach((row) => {
      gridRef.current.updateRows([row]);
    });
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    };
    setHasUnsavedRows(false);
    setSnackbar({ children: "Alterações descartadas!", severity: "success" });
  }, [gridRef]);

  const saveAllChanges = React.useCallback(() => {
    let promises = [];
    setIsSaving(true);
    Object.values(unsavedChangesRef.current.unsavedRows).forEach((row) => {
      if (row._action === "delete") {
        promises.push(deleteMutation(row.id));
      } else {
        let body = { ...row };
        delete body["image"];
        delete body["og_image"];
        delete body["source_url"];
        promises.push(
          updateMutation({
            id: row.id,
            body,
          }),
        );
      }
    });
    Promise.all(promises)
      .then((responses) => {
        let hasError = false;
        responses.forEach((response) => {
          if (response["error"]) {
            setSnackbar({
              children: Object.values(response["error"]["data"]).reduce(
                (acc, err_list) => acc + err_list.toString(),
                "",
              ),
              severity: "error",
            });
            hasError = true;
          }
          console.log(response);
        });
        if (hasError === false) {
          refetch();
          setSnackbar({
            children: "Alterações salvas com sucesso!",
            severity: "success",
          });
          unsavedChangesRef.current = {
            unsavedRows: {},
            rowsBeforeChange: {},
          };
          setHasUnsavedRows(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setSnackbar({
          children: "Erro ao salvar as alterações",
          severity: "error",
        });
      });
    setIsSaving(false);
  }, [refetch, deleteMutation, updateMutation]);

  // All this hooks below it's just to improve the performance of the Grid
  const getRowClassName = React.useCallback(({ id }) => {
    const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
    if (unsavedRow) {
      if (unsavedRow._action === "delete") {
        return "row--removed";
      }
      return "row--edited";
    }
    return "";
  }, []);
  const columns = React.useMemo(
    () => getColumns(gridRef, unsavedChangesRef, setHasUnsavedRows),
    [],
  );
  const getRowId = React.useCallback((row) => row.id, []);
  const pageSizeOptions = React.useMemo(() => [20, 50, 100], []);
  const getSlots = React.useMemo(
    () => ({ toolbar: DataGridEditableRowToolbar }),
    [],
  );
  const getSlotProps = React.useMemo(() => {
    return {
      toolbar: {
        hasUnsavedRows,
        discardAllChanges,
        saveAllChanges,
        createLink,
      },
    };
  }, [hasUnsavedRows]);
  const getEmptyRows = React.useMemo(() => [], []);
  if (error) {
    setSnackbar({ children: "Erro ao carregar os dados!", severity: "error" });
  }

  return (
    <>
      <StyledDataGrid
        aria-label="Lista de categorias"
        sortingOrder={["desc", "asc"]}
        disableRowSelectionOnClick
        disableColumnResize
        disableDensitySelector
        apiRef={gridRef}
        editMode="row"
        columns={columns}
        loading={isFetching || isSaving}
        rows={data || getEmptyRows}
        getRowId={getRowId}
        paginationMode="client"
        pageSizeOptions={pageSizeOptions}
        processRowUpdate={processRowUpdate}
        slots={getSlots}
        slotProps={getSlotProps}
        getRowClassName={getRowClassName}
        {...props}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
};

export default React.memo(DataGridEditableRow);
